'use client';

import type { FirestoreError } from 'firebase/firestore';
import { createContext } from 'react';
import type { UseQueryResult } from 'react-query';

import type { IWorkspace } from '@/shared/firebase/models/workspace.interface';

type ActiveWorkspaceContextValue = {
  activeWorkspace: IWorkspace | null;
  state?: Omit<UseQueryResult<IWorkspace | undefined, FirestoreError>, 'data'>;
};

export const ActiveWorkspaceContext =
  createContext<ActiveWorkspaceContextValue>({
    activeWorkspace: null,
  });
